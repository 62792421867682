(function($){
    jQuery(document).on('gform_post_render', function(event, form_id, current_page){
        $('.amenities-slider').slick({
            responsive: [
                {
                    breakpoint: 2400,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 1199,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ],
            prevArrow: '<button type="button" class="slick-prev" aria-label="Previous"><img src="/wp-content/themes/estimates/dist/images/arrow-prev.png" alt="Previous" /></button>',
            nextArrow: '<button type="button" class="slick-next" aria-label="Next"><img src="/wp-content/themes/estimates/dist/images/arrow-next.png" alt="Next" /></button>',
        });

        $('.social-slider').slick({
            responsive: [
                {
                    breakpoint: 2400,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 1199,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ],
            adaptiveHeight: true,
            arrows: false,
            dots: true
        });

        $('.reviews-slider').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            prevArrow: '<button type="button" class="slick-prev" aria-label="Previous"><img src="/wp-content/themes/estimates/dist/images/arrow-prev.png" alt="Previous" /></button>',
            nextArrow: '<button type="button" class="slick-next" aria-label="Next"><img src="/wp-content/themes/estimates/dist/images/arrow-next.png" alt="Next" /></button>',
        });

        // Inline label forms: Add active class if input has value
        $('.js-gform input[type=text], .js-gform input[type=tel], .js-gform input[type=email], .js-gform input[type=number]').each(function () {
            if ($(this).val().length) {
                $(this).closest('.gfield').addClass('active');
            }
        });

        // Inline label forms: Add active class on focus
        $('.js-gform input[type=text], .js-gform input[type=tel], .js-gform input[type=email], .js-gform input[type=number]').on('focus', function () {
            $(this).closest('.gfield').addClass('active');
        });

        // Inline label forms: Remove active class on blur
        $('.js-gform input[type=text], .js-gform input[type=tel], .js-gform input[type=email], .js-gform input[type=number]').on('blur', function () {
            if (!$(this).val().length || $(this).val().includes('_')) {
                $(this).closest('.gfield').removeClass('active');
            }
        });
        let lastScroll = 0;
        let hideNav = 0;
        var w = $(window).width();
        if (w > 1199) {
            window.addEventListener("scroll", () => {
                let currentScroll = window.pageYOffset;
                if (currentScroll - lastScroll > 0) {
                    if (currentScroll > 500 && hideNav == 0) {
                        hideNav = 1;
                        $('.nav .logo img').show();
                        $('.nav .phone a').css('display', 'flex');
                        $('.nav .container').height(75);
                        
                    }
                } else {
                    if (hideNav == 1) {
                        hideNav = 0;
                        $('.nav .logo img').hide();
                        $('.nav .phone a').hide();
                        $('.nav .container').height(50);
                    }
                }
                lastScroll = currentScroll;
            });
        }

        $(".js-to-form").click(function(e) {
            e.preventDefault();
            let navOffset = $('.js-gform').offset().top;
            $('html, body').animate({
                scrollTop: navOffset - 94
            }, 1000);
        });
    });

})(jQuery);